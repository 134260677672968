<template>
  <div class="home text-center mt-5 mb-5">
    <img draggable="false" oncontextmenu="return false;" class="img-fluid home-image" alt="tzxhome Message"  src="../assets/static/tzxhome.png">
  </div>
</template>

<script>
// @ is an alias to /src

</script>

<style scoped>
  .home-image{width: 750px;}
</style>
