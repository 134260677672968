<template>
    <div class="container toc-container">
        <h2>Allgemeine Geschäftsbedingungen</h2>
        <br>

        <h4>1. Geltungsbereich</h4>
            <h>Unsere allgemeinen Geschäftsbedingungen gelten, soweit nicht ausdrücklich etwas anderes vereinbart ist für alle Angebote, Aufträge, Kaufverträge und Lieferungen im 
                Geltungsbereich des Rechts der Bundesrepublik Deutschland, die wir an Auftraggeber (Käufer) leisten. Sie gelten gleichfalls für künftige Geschäftsbeziehungen, auch wenn sie nicht 
                nochmals ausdrücklich vereinbart werden. Insbesondere gelten sie auch für Geschäftsbeziehungen und Verträge, die über das Internetangebot der FTS Fair Trade + Service KG auf der 
                Homepage „https://www.TreZi-Fix.com" zustande kommen.</h>
            <br>
            <h>Sie gelten insbesondere gegenüber Unternehmern, juristischen Personen des öffentlichen Rechts undöffentlich rechtlichen Sondervermögen.</h>
            <br>
            <h>Geschäftsbedingungen des Partners, die von uns nicht ausdrücklich anerkannt werden, haben keine Gültigkeit, auch wenn wir ihnen nicht ausdrücklich widersprechen. </h>
            <br>
            <h>TreZi-Fix® ist das eingetragene Warenzeichen der FTS Fair Trade + Service KG. Die Verwendung dieses Zeichens bedarf der schriftlichen Zustimmung der FTS Fair Trade + Service KG. Für unter dem Namen „TreZi-Fix® " abgeschlossene Verträge gelten ebenfalls die hier angeführten Allgemeinen Geschäftsbedingungen.</h>
            <br>
            <h>Unsere Lieferungen und Leistungen erfolgen ausschließlich aufgrund der nachstehenden Bedingungen.</h>
            <br>
            
            <!-- 2. Abschnitt AGB -->
            <br>
            <h4>2. Allgemeine Bestimmungen</h4>
            <h>Sofern mündliche Vereinbarungen getroffen wurden, werden diese unverzüglich im Einzelnen schriftlich bestätigt.</h>
            <br>
            <h>Bestellungen gelten als Kaufantrag und werden erst mit unserer Auftragsbestätigung verbindlich. Hiervon sind separat vereinbarte unbefristete und langfristige Verträge ausgenommen, die jeweilige Bestellung stellt bei solchen Verträgen einen Abruf dar.</h>
            <br>
            <h>Die in Prospekten und Katalogen enthaltenen Angaben, Maße und Abbildungen sind branchenübliche Näherungswerte, es sei denn, dass sie von uns ausdrücklich als verbindlich bezeichnet wurden.</h>
            <br>
            
            <!-- 3. Abschnitt AGB -->
            <br>
            <h4>3. Langfrist- und Abrufverträge, Preisanpassung</h4>
            <h> Unbefristete Verträge sind mit einer Frist von drei Monaten kündbar. </h>
            <br>
            <h>Tritt bei Langfristverträgen (Verträge mit einer Laufzeit von mehr als sechs Monaten und unbefristete Verträge) eine wesentliche Änderung der Lohn-, Material- und Energiekosten 
                ein, so ist jeder Vertragspartner berechtigt, eine angemessene Anpassung des Preises unter Berücksichtigung dieser Faktoren zu verlangen. </h>
            <br>
            <h>Ist eine verbindliche Bestellmenge nicht vereinbart, so legen wir unserer Kalkulation, die vom Partner für einen bestimmten Zeitraum erwartete, unverbindliche Bestellmenge(Zielmenge) zugrunde.</h>
            <br>
            <h>Nimmt der Partner weniger als die Zielmenge ab, sind wir berechtigt, den Stückpreis angemessen zu erhöhen. Nimmt er mehr als die Zielmenge ab, senken wir den Stückpreis angemessen, soweit der Partner den Mehrbedarf mindestens zwei Monate vor der Lieferung angekündigt hat.</h>
            <br>
            <h>Bei Lieferverträgen auf Abruf sind uns, wenn nichts anderes vereinbart ist, verbindliche Mengen mindestens vier Wochen vor dem Liefertermin durch Abruf mitzuteilen.</h>
            <br>
            <h>Mehrkosten, die durch einen verspäteten Abruf oder nachträgliche Änderungen des Abrufs hinsichtlich Zeit oder Menge durch unseren Partner verursacht sind, gehen zu seinen Lasten; dabei ist unsereKalkulation maßgebend.</h>
            <br>

            <!-- 4. Abschnitt AGB -->
            <br>
            <h4>4. Vertraulichkeit</h4>
            <h>Jeder Vertrags Partner wird alle Unterlagen ( dazu zählen auch Muster, Modelle, technische Zeichnungen und Daten) und Kenntnisse, die er aus der Geschäftsverbindung erhält,  nur für die gemeinsam verfolgten Zwecke verwenden und mit der gleichen Sorgfalt wie entsprechende eigene Unterlagen und Kenntnisse gegenüber Dritten geheim halten,  wenn der andere Vertragspartner sie als vertraulich bezeichnet oder an ihrer Geheimhaltung ein offenkundiges Interesse hat.  Diese Verpflichtung beginnt ab erstmaligem Erhalt der Unterlagen oder Kenntnisse und endet 36 Monate nach Ende der Geschäftsverbindung</h>
            <br>
            <h>Die Verpflichtung gilt nicht für Unterlagen und Kenntnisse, die allgemein bekannt sind oder die bei Erhalt dem Vertragspartner bereits bekannt waren, ohne dass er zur Geheimhaltung verpflichtet war, oder die danach von einem zur Weitergabe berechtigten Dritten übermittelt werden oder die von dem empfangenden Vertragspartner ohne Verwertung geheim zu haltender Unterlagen oder Kenntnisse des anderen Vertragspartners entwickelt werden.</h>
            <br>

            <!-- 5. Abschnitt AGB -->
            <br>
            <h4>5. Zeichnungen und Beschreibung, beigestellte Waren</h4>
            <h>Stellt ein Vertragspartner dem anderen Zeichnungen oder technische Unterlagen über die zu liefernde Ware oder ihre Herstellung zur Verfügung, bleiben diese Eigentum des vorlegenden Vertragspartners.</h>
            <br>
            <h>Ware, die der FTS Fair Trade + Service KG zur Weiterbe- und/oder -verarbeitung oder Konfektionierung zur Verfügung gestellt wurde, bleibt im Eigentum der Vertragspartners. Die FTS Fair Trade + Service KG haftet für die Vollständigkeit und Unversehrtheit dieser Ware nur nach geprüfter Übernahme und nur während der Zeit des Verbleibs in deren Geschäftsräumen.</h>
            <br>
            
            <!-- 6. Abschnitt AGB -->
            <br>
            <h4>6. Muster und Fertigungsmittel</h4>
            <h>Die Herstellungskosten für Muster und Fertigungsmittel (Werkzeuge, Formen, Schablonen etc.) werden, sofern nichts anderes vereinbart ist, von der zu liefernden Ware gesondert in Rechnung gestellt. Dies gilt auch für Fertigungsmittel, die infolge von Verschleiß ersetzt werden müssen.</h>
            <br>
            <h>Die Kosten für die Instandhaltung und sachgemäße Aufbewahrung sowie das Risiko einer Beschädigung oder Zerstörung der Fertigungsmittel werden von uns getragen. </h>
            <br>
            <h>Setzt der Partner während der Anfertigungszeit der Muster oder Fertigungsmittel die Zusammenarbeit aus oder beendet er sie, gehen alle bis dahin entstandenen Herstellungskosten zu seine Lasten.</h>
            <br>
            <h>Die Fertigungsmittel bleiben, auch wenn der Partner sie bezahlt hat, mindestens bis zur Abwicklung des Liefervertrages in unserem Besitz. Danach ist der Partner berechtigt, die Fertigungsmittel herauszuverlangen, wenn über den Zeitpunkt der Herausgabe eine einvernehmliche Regelung erzielt wurde und der Partner seinen vertraglichen Verpflichtungen in vollem Umfang nachgekommen ist.</h>
            <br>
            <h>Wir verwahren die Fertigungsmittel unentgeltlich drei Jahre nach der letzten Lieferung an unseren Partner, Danach fordern wir unseren Partner schriftlich auf, sich innerhalb von sechs Wochen zur weiteren Verwendung zu äußern. Unsere Pflicht zur Verwahrung endet, wenn innerhalb dieser sechs Wochen keine Äußerung erfolgt oder keine neue Bestellung aufgegeben wird.</h>
            <br>
            <h>Abnehmerbezogene Fertigungsmittel dürfen von uns nur mit vorheriger schriftlicher Zustimmung unseres Partners für Zulieferungen an Dritte verwendet werden.</h>
            <br>
            
            <!-- 7. Abschnitt AGB -->
            <br>
            <h4>7. Preise</h4>
            <h>Unsere Preise verstehen sich in Euro ausschließlich Umsatzsteuer, innerhalb der Bundesrepublik Deutschland inklusive Lieferung „frei Haus" und inklusive Verpackung.</h>
            <br>

            <!-- 8. Abschnitt AGB -->
            <br>
            <h4>8. Zahlungsbedingungen</h4>
            <h>Alle Rechnungen sind innerhalb von 30 Tagen ab Rechnungsdatum netto Kasse zur Zahlung fällig.</h>
            <br>
            <h>Bei Zahlung innerhalb 8 Tagen nach Rechnungsdatum gewähren wir 2% Skonto.</h>
            <br>
            <h>Haben wir unstreitig teilweise fehlerhafte Ware geliefert, ist unser Partner dennoch verpflichtet, die Zahlung für den fehlerfreien Anteil zu leisten, es sei denn, dass die Teillieferung für ihn nicht von Interesse ist. Im Übrigen kann der Partner nur mit rechtskräftig festgestellten oder unbestrittenen Gegenansprüchen aufrechnen.</h>
            <br>
            <h>Bei Zielüberschreitung sind wir berechtigt, Verzugszinsen in Höhe des Satzes in Rechnung zu stellen, den die Bank uns für Kontokorrentkredite berechnet, mindestens aber in Höhe von acht Prozentpunkten über dem jeweiligen Basiszinssatz der Europäischen Zentralbank.</h>
            <br>
            <h>Bei Zahlungsverzug können wir nach schriftlicher Mitteilung an den Partner die Erfüllung unserer Verpflichtungen bis zum Erhalt der Zahlungen einstellen.</h>
            <br>
            <h>Wechsel und Schecks werden nur nach Vereinbarung sowie nur erfüllungshalber und unter der Voraussetzung ihrer Diskontierbarkeit angenommen. Diskontspesen werden vom Tage der Fälligkeit des Rechnungsbetrages an berechnet. Eine Gewähr für rechtzeitige Vorlage des Wechsels und Schecks und für Erhebung von Wechselprotest wird ausgeschlossen.</h>
            <br>
            <h>Wenn nach Vertragsschluss erkennbar wird, dass unser Zahlungsanspruch durch mangelnde Leistungsfähigkeit des Partners gefährdet wird, so können wir die Leistung verweigern und dem Partner eine angemessene Frist bestimmen, in welcher er Zug, um Zug gegen Lieferung zu zahlen oder Sicherheit zu leisten hat. Bei Verweigerung des Partners oder erfolglosem Fristablauf sind wir berechtigt, vom Vertrag zurückzutreten und Schadenersatz zu verlangen.</h>
            <br>
            
            <!-- 9. Abschnitt AGB -->
            <br>
            <h4>9. Lieferung</h4>
            <h>Sofern nichts anderes vereinbart ist, liefern wir innerhalb der Bundesrepublik Deutschland „frei Haus". Maßgebend für die Einhaltung des Liefertermins oder der Lieferfrist ist die Meldung der Versandbzw. Abholbereitschaft durch uns. </h>
            <br>
            <h>Die Lieferfrist beginnt mit der Absendung unserer Auftragsbestätigung und verlängert sich angemessen, wenn zumindest eine Voraussetzungen der Nummer 15.1 vorliegen. Angegebenen Liefertermine beziehen sich auf den Übergabezeitpunkt an die zum Transport bestimmte Person oder Firma. </h>
            <br>
            <h>Teillieferungen sind in zumutbarem Umfang zulässig. Sie werden gesondert in Rechnung gestellt.</h>
            <br>
            <h>Innerhalb einer Toleranz von 5 Prozent der Gesamtauftragsmenge sind fertigungsbedingte Mehroder Minderlieferungen zulässig. Ihrem Umfang entsprechend ändert sich dadurch der Gesamtpreis.</h>
            <br>
            <h>Fixtermine bedürfen einer ausdrücklichen und gesonderten Vereinbarung.</h>
            <br>

            <!-- 10. Abschnitt AGB-->
            <br>
            <h4>10. Versand und Gefahrübergang</h4>
            <h>Sofern nicht die Lieferung „frei Haus" vereinbart wurde, ist abholbereit gemeldete Ware vom Partner unverzüglich zu übernehmen, bzw. abzuholen. Anderenfalls sind wir berechtigt, sie nach eigener Wahl zu versenden oder auf Kosten und Gefahr des Partners zu lagern. Mangels besonderer Vereinbarung wählen wir das Transportmittel und den Transportweg.</h>
            <br>
            <h>Mit der Übergabe an die Bahn, den Spediteur oder den Frachtführer bzw. mit Beginn der Lagerung, spätestens jedoch mit Verlassen des Werkes oder Lagers, geht die Gefahr auf den Partner über, und zwar auch, wenn wir die Anlieferung übernommen haben.</h>
            <br>
            
            <!-- 11. Abschnitt AGB-->
            <br>
            <h4>11. Lieferverzug</h4>
            <h>Können wir absehen, dass die Ware nicht innerhalb der Lieferfrist geliefert werden kann, so werden wir den Partner unverzüglich und schriftlich davon in Kenntnis setzen, ihm die Gründe hierfür mitteilen sowie nach Möglichkeit den voraussichtlichen Lieferzeitpunkt nennen.</h>
            <br>
            <h>Verzögert sich die Lieferung durch einen in der Nummer 15.1 aufgeführten Umstand oder durch ein Handeln oder Unterlassen des Partners, so wird eine den Umständen angemessene Verlängerung der Lieferfrist gewährt.</h>
            <br>
            <h>Der Partner ist zum Rücktritt vom Vertrag nur berechtigt, wenn wir die Nichteinhaltung des Liefertermins zu vertreten haben und er uns erfolglos eine angemessene Nachfrist gesetzt hat.</h>
            <br>

            <!-- 12. Abschnitt AGB-->
            <br>
            <h4>12.Eigentumsvorbehalt</h4>
            <h>Wir behalten uns das Eigentum an der gelieferten Ware bis zur Erfüllung aller Forderungen aus der Geschäftsverbindung mit dem Partner vor.</h>
            <br>
            <h>Der Partner ist berechtigt, diese Waren im ordentlichen Geschäftsgang zu veräußern, solange er seinen Verpflichtungen aus der Geschäftsverbindung mit uns rechtzeitig nachkommt. Er darf jedoch die Vorbehaltsware weder verpfänden noch zur Sicherheit übereignen. Er ist verpflichtet, unsere Rechte beim kreditierten Weiterverkauf der Vorbehaltsware zu sichern.</h>
            <br>
            <h>Bei Pflichtverletzungen des Partners, insbesondere bei Zahlungsverzug, sind wir nach erfolglosem Ablauf einer dem Partner gesetzten angemessenen Frist zur Leistung zum Rücktritt und zur Rücknahme berechtigt; die gesetzlichen Bestimmungen über die Entbehrlichkeit einer Fristsetzung bleiben unberührt. Der Partner ist zur Herausgabe verpflichtet.</h>
            <br>
            <h>Wir sind zum Rücktritt vom Vertrag berechtigt, wenn Antrag auf Eröffnung, des Insolvenzverfahrens über das Vermögen des Partners gestellt wird.</h>
            <br>
            <h>Alle Forderungen und Rechte aus dem Verkauf oder einer gegebenenfalls dem Partner gestatteten Vermietung von Waren, an denen uns Eigentumsrechte zustehen, tritt der Partner schon jetzt zur Sicherung an uns ab. Wir nehmen die Abtretung hiermit an.</h>
            <br>
            <h>Eine etwaige Be- oder Verarbeitung der Vorbehaltsware nimmt der Partner stets für uns vor. Wird die Vorbehaltsware mit anderen, nicht uns gehörenden Gegenständen verarbeitet oder untrennbar vermischt, so erwerben wir das Miteigentum an der neuen Sache im Verhältnis des Rechnungswertes der Vorbehaltsware zu den anderen verarbeiteten oder vermischten Gegenständen zum Zeitpunkt der Verarbeitung oder Vermischung.</h>
            <br>
            <h>Werden unsere Waren mit anderen beweglichen Gegenständen zu einer einheitlichen Sache verbunden oder untrennbar vermischt und ist die andere Sache als Hauptsache anzusehen, so überträgt der Partner uns anteilmäßig Miteigentum, soweit die Hauptsache ihm gehört. Der Partner verwahrt das Eigentum oder Miteigentum für uns. Für die durch Verarbeitung oder Verbindung bzw. Vermischung entstellende Sache gilt im Übrigen das Gleiche wie für die Vorbehaltsware.</h>
            <br>
            <h>Über Zwangsvollstreckungsmaßnahmen Dritter in die Vorbehaltsware, in die uns abgetretenen Forderungen oder in sonstige Sicherheiten hat der Partner uns unverzüglich unter Übergabe der für eine Intervention notwendigen Unterlagen zu unterrichten. Dies gilt auch für Beeinträchtigungen sonstiger Art.</h>
            <br>
            <h>Übersteigt der Wert der bestehenden Sicherheiten die gesicherten Forderungen insgesamt um mehr als 20 Prozent, so sind wir auf Verlangen des Partners insoweit zur Freigabe von Sicherheiten nach unserer Wahl verpflichtet.</h>
            <br>

            <!-- 13. Abschnitt AGB-->
            <br>
            <h4>13. Sachmängel</h4>
            <h>Die Beschaffenheit der Ware richtet sich ausschließlich nach den vereinbarten technischen Liefervorschriften. Falls wir nach Zeichnungen, Spezifikationen, Mustern usw. unseres Partners zu liefern haben, übernimmt dieser das Risiko der Eignung für den vorgesehenen Verwendungszweck. Entscheidend für den vertragsgemäßen Zustand der Ware ist der Zeitpunkt des Gefahrübergangs gemäß der Nummer 10.2.</h>
            <br>
            <h>Für Sachmängel, die durch ungeeignete oder unsachgemäße Verwendung, fehlerhafte Montage bzw. Inbetriebsetzung durch den Partner oder Dritte, übliche Abnutzung, fehlerhafte oder nachlässige Behandlung entstehen, stehen wir ebenso wenig ein wie für die Folgen unsachgemäßer und ohne unsere Einwilligung vorgenommener Änderungen oder Instandsetzungsarbeiten des Partners oder Dritter. Gleiches gilt für Mängel, die den Wert oder die Tauglichkeit der Ware nur unerheblich mindern.</h>
            <br>
            <h>Kommt es auf Wunsch des Bestellers oder aus Gründen, die der Besteller zu vertreten hat (z.B. verspätete technische Klarstellung) zu besonderer Eilbedürftigkeit und kann hierin begründet eine Qualitätskontrolle nicht mehr rechtzeitig durchgeführt werden, haftet die FTS Fair Trade + Service KG nicht für Qualitätsbeanstandungen.</h>
            <br>
            <h>Die Verjährung der Sachmängelansprüche richtet sich, soweit nichts anderes vereinbart ist, nach demGesetz, <br> oder <br> Sachmängelansprüche verjähren in zwölf Monaten. Dies gilt nicht, soweit das Gesetz längere Fristen zwingend vorschreibt, insbesondere für Mängel bei einem Bauwerk und bei einer Ware, die entsprechend ihrer üblichen Verwendungsweise für ein Bauwerk verwendet wurde und dessen Mangelhaftigkeit verursacht hat</h>
            <br>
            <h>Wurde eine Abnahme der Ware oder eine Erstmusterprüfung vereinbart, ist die Rüge von Mängeln ausgeschlossen, die der Partner bei sorgfältiger Abnahme oder Erstmusterprüfung hätte feststellen können.</h>
            <br>
            <h>Die FTS Fair Trade + Service KG ist Gelegenheit zu geben, den gerügten Mangel festzustellen. Beanstandete Ware ist auf Verlangen unverzüglich an uns zurückzusenden; wir übernehmen die Transportkosten, wenn die Mängelrüge berechtigt ist. Wenn der Partner diesen Verpflichtungen nicht nachkommt oder ohne unsere Zustimmung Änderungen an der bereits beanstandeten Ware vornimmt, verliert er etwaige Sachmängelansprüche.</h>
            <br>
            <h>Bei berechtigter, fristgemäßer Mängelrüge bessern wir nach unserer Wahl die beanstandete Ware nach oder liefern einwandfreien Ersatz. </h>
            <br>
            <h>Kommen wir diesen Verpflichtungen nicht oder nicht vertragsgemäß innerhalb einer angemessenen Zeit nach, so kann der Partner uns schriftlich eine letzte Frist setzen, innerhalb derer wir unseren Verpflichtungen nachzukommen haben. Nach erfolglosem Ablauf dieser Frist kann der Partner Minderung des Preises verlangen, vom Vertrag zurücktreten oder die notwendige Nachbesserung selbst oder von einem Dritten auf unsere Kosten und Gefahr vornehmen lassen. Eine Kostenerstattung ist ausgeschlossen, soweit die Aufwendungen sich erhöhen, weil die Ware nach unserer Lieferung an einen anderen Ort verbracht worden ist, es sei denn, dies entspricht dem bestimmungsgemäßen Gebrauch der Ware.</h>
            <br>
            <h>Gesetzliche Rückgriffsansprüche des Partners gegen uns bestehen nur insoweit, als der Partner mit seinem Abnehmer keine Vereinbarungen getroffen hat, die über die gesetzlichen Mängelansprüche hinausgehen. Eine Kostenerstattung ist ausgeschlossen, soweit die Aufwendungen sich erhöhen, weil die Ware nach unserer Lieferung an einen anderen Ort verbracht worden ist, es sei denn, dies entspricht dem bestimmungsgemäßen Gebrauch der Ware.</h>
            <br>

            <!-- 14. Abschnitt AGB-->
            <br>
            <h4>14. Sonstige Ansprüche, Haftung</h4>
            <h>Soweit sich nachstehend nichts anderes ergibt, sind sonstige und weitergehende Ansprüche des Partners gegen uns ausgeschlossen. Dies gilt insbesondere für Schaden­ersatzansprüche wegen Verletzung von Pflichten aus dem Schuldverhältnis und aus unerlaubter Handlung. Wir haften deshalb nicht für Schäden, die nicht an der gelie­ferten Ware selbst entstanden sind. Vor allem haften wir nicht für entgangenen Ge­winn oder sonstige Vermögensschäden des Partners.</h>
            <br>
            <h>Vorstehende Haftungsbeschränkungen gelten nicht bei Vorsatz, bei grober Fahrlässig­keit unserer gesetzlichen Vertreter oder leitenden Angestellten sowie bei schuldhafter Verletzung wesentlicher Vertragspflichten. Bei schuldhafter Verletzung wesentlicher Vertragspflichten haften wir außer in den Fällen des Vorsatzes oder der groben Fahrlässigkeit unserer gesetzlichen Vertreter oder leitenden Angestellten nur für den vertragstypischen, vernünftigerweise vorhersehbaren Schaden.</h>
            <br>
            <h>Die Haftungsbeschränkung gilt ferner nicht in den Fällen, in denen nach Produkthaftungsgesetz bei Fehlern der gelieferten Ware für Personen- oder Sachschäden an pri­vat genutzten Gegenständen gehaftet wird. Sie gilt auch nicht bei der Verletzung von Leben, Körper oder Gesundheit und beim Fehlen von zugesicherten Eigenschaften, wenn und soweit die Zusicherung gerade bezweckt hat, den Partner gegen Schäden, die nicht an der gelieferten Ware selbst entstanden sind, abzusichern.</h>
            <br>
            <h>Soweit unsere Haftung ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung unserer Angestellten, Arbeitnehmer, Mitarbeiter, gesetzlichen Ver­treter und Erfüllungsgehilfen.</h>
            <br>
            <h>Die gesetzlichen Regelungen zur Beweislast bleiben hiervon unberührt.</h>
            <br>

            <!-- 15. Abschnitt AGB-->
            <br>
            <h4>15. Höhere Gewalt</h4>
            <h>Höhere Gewalt, Arbeitskämpfe, Unruhen, behördliche Maßnahmen, Ausbleiben von Zulieferungen unserer Lieferanten und sonstige unvorhersehbare, unabwendbare und schwerwiegende Ereignisse befreien die Vertragspartner für die Dauer der Störung und im Umfang ihrer Wirkung von den Leistungspflichten. Dies gilt auch, wenn diese Ereignisse zu einem Zeitpunkt eintreten, in dem sich der betroffene Vertragspartner in Verzug befindet, es sei denn, dass er den Verzug vorsätzlich oder grob fahrlässig ver­ursacht hat.</h>
            <br>
            <h>Die Vertragspartner sind verpflichtet, im Rahmen des Zumutbaren unverzüglich die erforderlichen Informationen zu geben und ihre Verpflichtungen den veränderten Ver­hältnissen nach Treu und Glauben anzupassen.</h>
            <br>

            <!-- 16. Abschnitt AGB-->
            <br>
            <h4>16. Erfüllungsort, Gerichtsstand und anwendbares Recht</h4>
            <h>Sofern sich aus der Auftragsbestätigung oder aus gesondert schriftlichen Vereinbarungen nichts anderes ergibt, ist unser Geschäftssitz Erfüllungsort.</h>
            <br>
            <h>Für alle Rechtsstreitigkeiten, auch im Rahmen eines Wechsel- und Scheckprozesses, gilt der Gerichtsstand Erlangen als vereinbart. Wir sind auch berechtigt, am Sitz des Partners zu klagen.</h>
            <br>
            <h>Auf die Vertragsbeziehung ist ausschließlich das Recht der Bundesrepublik Deutschland anzuwenden.</h>
            <br>
            
            <!-- 17. Abschnitt AGB-->
            <br>
            <h4>17. Datenschutz-Hinweis</h4>
            <h>Entsprechend dem Bundesdatenschutzgesetz weisen wir darauf hin, dass wir Daten des Auftraggeber speichern und verarbeiten.</h>
            <br>

            <!-- 18. Abschnitt AGB-->
            <br>
            <h4>18. Salvatorische Klausel</h4>
            <h>Sollten eine oder mehrere Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirk­sam sein oder werden, so wird hierdurch die Wirksamkeit der übrigen Bestimmungen und des Vertrages im Ganzen nicht berührt. Anstelle der unwirksamen Klausel soll dasjenige gelten, was ihr rechtlich und wirtschaftlich am nächsten kommt.</h>
            
    </div>

</template>

<style>
    .toc-container{margin-bottom: 100px;}
</style>

<script>

</script>