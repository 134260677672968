<template>
    <div class="container product-container">
        <h2>Produkte</h2>
        <br>
        <div class="table-responsive">
            <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Art.-Nr</th>
                            <th scope="col">Artikelname</th>
                            <th scope="col">Größe</th>
                            <th scope="col"></th>
                            <th scope="col">Bohrung Ø mm mindestens</th>
                            <th scope="col">Bohrung Ø mm empfohlen</th>
                            <th scope="col">Zugkraft max. N</th>
                            <th scope="col">VPE</th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider">
                        <tr>
                            <td>44 5035 20</td>
                            <td>TreZi-Fix  Gewindeschale</td>
                            <td>M20</td>
                            <td><img src="../assets/product/tzx_gs_ty.png" alt="N/A" draggable="false"></td>
                            <td>40</td>
                            <td>40</td>
                            <td>100.050</td>
                            <td>50</td>
                        </tr>
                        <tr>
                            <td>44 5025 12</td>
                            <td>TreZi-Fix  Gewindeschale</td>
                            <td>M12</td>
                            <td><img src="../assets/product/tzx_gs_ty.png" alt="N/A" draggable="false"></td>
                            <td>32</td>
                            <td>36</td>
                            <td>45.000</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>44 2825 10</td>
                            <td>TreZi-Fix  Gewindeschale</td>
                            <td>M10</td>
                            <td><img src="../assets/product/tzx_gs_ty.png" alt="N/A" draggable="false"></td>
                            <td>30</td>
                            <td>30</td>
                            <td>31.000</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>44 2025 08</td>
                            <td>TreZi-Fix  Gewindeschale</td>
                            <td>M8</td>
                            <td><img src="../assets/product/tzx_gs_ty.png" alt="N/A" draggable="false"></td>
                            <td>18</td>
                            <td>20</td>
                            <td>14.000</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>44 1516 06</td>
                            <td>TreZi-Fix  Gewindeschale</td>
                            <td>M6</td>
                            <td><img src="../assets/product/tzx_gs_ty.png" alt="N/A" draggable="false"></td>
                            <td>16</td>
                            <td>18</td>
                            <td>11.500</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>44 2835 10</td>
                            <td>TreZi-Fix  Gewinderolle</td>
                            <td>M10</td>
                            <td><img src="../assets/product/tzx_gr_ty.png" alt="N/A" draggable="false"></td>
                            <td>30</td>
                            <td>30</td>
                            <td>41.000</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>44 2025 09</td>
                            <td>TreZi-Fix  Gewinderolle</td>
                            <td>M8</td>
                            <td><img src="../assets/product/tzx_gr_ty.png" alt="N/A" draggable="false"></td>
                            <td>18</td>
                            <td>20</td>
                            <td>28.800</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>44 1516 07</td>
                            <td>TreZi-Fix  Gewinderolle</td>
                            <td>M6</td>
                            <td><img src="../assets/product/tzx_gr_ty.png" alt="N/A" draggable="false"></td>
                            <td>18</td>
                            <td>18</td>
                            <td>14.000</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>44 000</td>
                            <td>TreZi-Fix  Montageschlüssel</td>
                            <td>M20</td>
                            <td><img src="../assets/product/tzx_mtg_ty.png" alt="N/A" draggable="false"></td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>44 001</td>
                            <td>TreZi-Fix  Montageschlüssel</td>
                            <td>M12</td>
                            <td><img src="../assets/product/tzx_mtg_ty.png" alt="N/A" draggable="false"></td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>44 002</td>
                            <td>TreZi-Fix  Montageschlüssel</td>
                            <td>M10</td>
                            <td><img src="../assets/product/tzx_mtg_ty.png" alt="N/A" draggable="false"></td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>44 0010</td>
                            <td>TreZi-Fix  Montageschlüssel</td>
                            <td>M8</td>
                            <td><img src="../assets/product/tzx_mtg_ty.png" alt="N/A" draggable="false"></td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>44 0017</td>
                            <td>TreZi-Fix  Montageschlüssel</td>
                            <td>M6</td>
                            <td><img src="../assets/product/tzx_mtg_ty.png" alt="N/A" draggable="false"></td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>44 003</td>
                            <td>TreZi-Fix  Ringschlüssel</td>
                            <td>SW 12</td>
                            <td><img src="../assets/product/tzx_rs_ty.png" alt="N/A" draggable="false"></td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>44 004</td>
                            <td>TreZi-Fix  Ringschlüssel</td>
                            <td>SW 9</td>
                            <td><img src="../assets/product/tzx_rs_ty.png" alt="N/A" draggable="false"></td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>44 0014</td>
                            <td>TreZi-Fix  Ringschlüssel</td>
                            <td>SW 7</td>
                            <td><img src="../assets/product/tzx_rs_ty.png" alt="N/A" draggable="false"></td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
</template>

<style>
    table tr img{width: 32px;}
    .product-container{margin-bottom: 125px;}


    @media only screen and (max-width: 436px){
        table tr img{width: 16px;}
        th{font-size: 5pt}
        td{font-size: 4pt;}
    }
</style>

