<template>
  <nav class="navbar navbar-expand-lg ">
  <div class="container-fluid">
    <router-link class="navbar-brand" id="nav-brand" to="/">TreZi-Fix</router-link>
    
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse " id="navbarSupportedContent">
      
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
            <a class="nav-link"><router-link to="/">Home</router-link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link"><router-link to="/Anwendung">Anwendung</router-link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link"><router-link to="/Produkte">Produkte</router-link></a>
          </li>
      </ul>
      <button type="button" class="btn btn-warning float-start" data-bs-toggle="modal" data-bs-target="#ContactModal">Kontakt</button>
      <div class="modal fade" id="ContactModal" tabindex="-1" aria-labelledby="ContactModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="ContactModalLabel">Kontakt</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <h6>FTS Fair Trade + Service KG - TreZi-Fix</h6>
              <h>Erich Feilner</h>
              <br>
              <h>Gleißenberg 31</h>
              <br>
              <h>DE-96152 Burghaslach</h>
              <br><br>
              <h>Fon: +49 / (0) 9552 / 92 11 - 93</h>
              <br>
              <h>Fax: +49 / (0) 9552 / 92 11 - 94</h>
              <br>
              <h>E-Mail:<a href="mailto:ftskg@trezi-fix.com" class="text-decoration-underline">ftskg@trezi-fix.com</a></h>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
    export default{
        data() {
            return {
                name: 'Navigation'
            };
        }
    };
</script>

<style>
  #nav-brand{color: #0F8ABD;font-size: 30px; font-weight: 600;padding: 10px;margin-left: 100px}

  nav a {font-weight: 400;color: #2c3e50;text-decoration: none;font-size: 20px;padding: 25px;}

  nav a.router-link-exact-active {color: #0F8ABD;}
  .modal-body{font-size: 12pt}
  .modal-body a{font-size: 12pt}

</style>