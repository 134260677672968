<script>
  import Navigation from './components/Navigation.vue';
  import tzxFooter from './components/Footer.vue';

  export default {
    name: 'App',
    compoments: {
      Navigation,
      tzxFooter
    }
  };
  
</script>

<template>
  <header>
    <Navigation />
  </header>
  
  <router-view/>
  <br>
  <br>
  <tzxFooter />
</template>

<style>

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
}

@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

body::-webkit-scrollbar{width: 0.45rem;}
body::-webkit-scrollbar-track{background: #acacac;}
body::-webkit-scrollbar-thumb{background: #0F8ABD; border-radius: 15px;}
body::-webkit-scrollbar-thumb:hover {background: rgba(15, 137, 189, 0.836);}

* {margin: 0px; padding: 0px; box-sizing: border-box; list-style: none; hyphens: auto;}

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

a{text-decoration: none; color: #0F8ABD;}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}



</style>
