<template>
    <footer class="text-center footer" style="background-color: #35383b;">
        <div class="container p-1">
            <div class="row">
                <div class="col-sm">
                    <ul>
                        <li>
                            <a><router-link to="AGB" class="text-light">Allg. Geschäftsbedingungen</router-link></a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm">
                    <ul>
                        <li>
                            <a><router-link to="Datenschutzerklaerung" class="text-light">Datenschutzerklärung</router-link></a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm">
                    <ul>
                        <li>
                            <a><router-link to="Impressum" class="text-light">Impressum</router-link></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="text-center p-2 text-light" style="background-color: #1A1C1E;">
            © FTS Fair Trade + Service KG | TreZi-Fix
        </div>
    </footer>
</template>

<script>
    export default{
        data() {
            return {
                name: 'tzxFooter'
            };
        }
    };
</script>

<style>
    

    footer {clear: both;position: relative;}
    footer a {color: #2c3e50}
    footer a.router-link-exact-active {color: #0F8ABD;}

</style>