import Vue from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Anwendung from '../views/Anwendung.vue'
import Produkte from '../views/Produkte.vue'
import Impressum from '../views/Impressum.vue'
import AGB from '../views/AGB.vue'
import Datenschutzerklaerung from '../views/Datenschutzerklaerung.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Anwendung',
    name: 'Anwendung',
    component: Anwendung
  },
  {
    path: '/Produkte',
    name: 'Produkte',
    component: Produkte
  },
  {
    path: '/Impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/AGB',
    name: 'AGB',
    component: AGB
  },
  {
    path: '/Datenschutzerklaerung',
    name: 'Datenschutzerklaerung',
    component: Datenschutzerklaerung
  },
]

const routing = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default routing
