<template>
    <div class="container imprint-container" >
        <h2>Impressum</h2>
        <br><h5>FTS Fair Trade + Service KG - TreZi-Fix</h5>
        <h>Geschäftsführer - Erich Feilner <br></h>
        <h>Gleißenberg 31 <br> DE-96152 Burghaslach<br></h>
        <br>
        <h>Fon: +49 / (0) 9552 / 92 11 - 93</h>
        <br>
        <h>Fax: +49 / (0) 9552 / 92 11 - 94</h>
        <br>
        <br>
        <a href="https://trezi-fix.com" class="text-decoration-underline">trezi-fix.com</a><br>
        <a href="mailto:ftskg@trezi-fix.com" class="text-decoration-underline">ftskg@trezi-fix.com</a>
        <br>
        <br>
        <h4>Umsatzsteuer</h4>
        <h>Umsatzsteuer-Identifikationsnummer gemäß §27 Umsatzsteuergesetz <br></h>
        <h6>Ust-ID.Nr: DE 265 127 157</h6>
        <br>
        <h4>Registereintrag</h4>
        <h>Eintragung im Handelsregister, Registergericht: Amtsgericht Fürth <br></h>
        <h6>Registernummer: HRA 9552</h6>
    </div>
</template>

<script>

</script>

<style>
        .imprint-container{margin-bottom: 100px}
</style>