import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Navigation from './components/Navigation.vue'
import tzxFooter from './components/Footer.vue'


createApp(App)
    .use(router)
    .component('Navigation', Navigation)
    .component('tzxFooter', tzxFooter)
    .mount('#app')
